import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import Icon from 'components/Icon'
import Spinner from 'components/Spinner'

import useKeyboard from 'hooks/keyboard'
import { disableScroll, enableScroll } from 'helpers/scroll'
import { RootState } from 'store/reducer'

import './index.less'

const Layer: React.FC<LayerProps> = (props) => {
  const {
    maxWidth,
    verticalAlignMiddle = true,
    children,
    onClose,
    isLoading,
    footer,
    hideCloseButton,
    className,
    ignoreScrolling,
  } = props

  const [contentSize, setContentSize] = useState({
    width: 0,
    height: 0,
  })
  const contentWrapperRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const portable = useSelector<RootState>((state) => state.layout.portable)

  useEffect(() => {
    const updatePosition = () => {
      if (contentWrapperRef.current) {
        const { width, height } = contentWrapperRef.current.getBoundingClientRect()

        setContentSize({ width, height })
      }
    }

    updatePosition()
    window.addEventListener('resize', updatePosition)

    return () => {
      window.removeEventListener('resize', updatePosition)
    }
  }, [children])

  useEffect(() => {
    if (!ignoreScrolling) {
      disableScroll(!portable)
    }

    return () => {
      if (!ignoreScrolling) {
        enableScroll(true)
      }
    }
  }, [ignoreScrolling, portable])

  useKeyboard('escape', () => {
    if (onClose) {
      onClose()
    }
  })

  return (
    <div className={classNames('layer', className)}>
      <div className="layer__background" onClick={onClose}></div>

      <div
        ref={contentWrapperRef}
        className={classNames(
          'layer__content-wrapper',
          {
            'layer__content-wrapper--vertical-align-middle': verticalAlignMiddle,
          },
        )}
        style={{
          maxWidth: (portable || !maxWidth) ? '' : `${maxWidth}px`,
          marginLeft: portable ? '' : `-${contentSize.width / 2}px`,
          marginTop: portable || !verticalAlignMiddle ? '' : `-${contentSize.height / 2}px`,
        }}
      >
        {!hideCloseButton && (
          <a className="layer__close-btn" onClick={onClose}>
            <Icon name="close" />
          </a>
        )}

        <div
          ref={contentRef}
          className={classNames(
            'layer__content',
            {
              'layer__content--has-footer': footer,
            },
          )}
        >
          {isLoading && (
            <Spinner className="layer__spinner" />
          )}

          {children}
        </div>

        {footer && (
          <div className="layer__footer">
            {footer}
          </div>
        )}
      </div>
    </div>
  )
}

export interface LayerProps {
  maxWidth?: number
  verticalAlignMiddle?: boolean
  onClose?: () => void
  isLoading?: boolean
  ignoreScrolling?: boolean
  footer?: React.ReactNode
  hideCloseButton?: boolean
  className?: string
}

export default Layer
