import React from 'react'
import { Input as AntInput } from 'antd'
import classNames from 'classnames'
import { useController, UseControllerProps } from 'react-hook-form'

import { InputProps } from 'antd/lib/input'

import './index.less'

const InputNumber: React.FC<InputNumberProps> = (props) => {
  const {
    name,
    className,
    defaultValue,
    rules = {},
    ...restProps
  } = props

  const {
    field: {
      onChange,
      onBlur,
      value,
      ref,
    },
    fieldState: { invalid },
  } = useController({
    name,
    rules: rules,
    defaultValue,
  })

  return (
    <AntInput
      {...restProps}
      type="number"
      ref={ref}
      onChange={(event) => onChange(parseInt(event.target.value) || null)}
      onBlur={onBlur}
      value={value}
      className={classNames(className, 'form__input-number', {
        'form__input-number--invalid': invalid,
      })}
    />
  )
}

type InputNumberProps = UseControllerProps & InputProps

export default InputNumber
