import React from 'react'
import { Radio as AntRadio } from 'antd'
import classNames from 'classnames'
import { useController, UseControllerProps } from 'react-hook-form'
import { RadioGroupProps as AntdRadioGroupProps } from 'antd/lib/radio'

const RadioGroup: React.FC<RadioGroupProps> & { Item: typeof AntRadio } = (props) => {
  const {
    name,
    className,
    defaultValue,
    children,
    rules = {},
    ...restProps
  } = props

  const {
    field: {
      onChange,
      value,
      ref,
    },
    fieldState: { invalid },
  } = useController({
    name,
    rules: rules,
    defaultValue,
  })

  return (
    <AntRadio.Group
      {...restProps}
      ref={ref}
      onChange={onChange}
      value={value}
      className={classNames(className, 'form__radio-group', {
        'form__radio-group--invalid': invalid,
      })}
    >
      {children}
    </AntRadio.Group>
  )
}

type RadioGroupProps = UseControllerProps & AntdRadioGroupProps

RadioGroup.Item = AntRadio

export default RadioGroup
