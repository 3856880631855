import React from 'react'
import { Input as AntInput } from 'antd'
import { InputProps as AntdInputProps } from 'antd/lib/input'
import classNames from 'classnames'
import { useController, UseControllerProps } from 'react-hook-form'

import './index.less'

const Input: React.FC<InputProps> = (props) => {
  const {
    name,
    className,
    defaultValue,
    rules = {},
    ...restProps
  } = props

  const {
    field: {
      onChange,
      value,
      ref,
      onBlur,
    },
    fieldState: { invalid },
  } = useController({
    name: name,
    rules: rules,
    defaultValue,
  })

  return (
    <AntInput
      {...restProps}
      ref={ref}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      className={classNames(className, 'form__input', {
        'form__input--invalid': invalid,
      })}
    />
  )
}

type InputProps = UseControllerProps & AntdInputProps

export default Input
