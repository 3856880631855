import React from 'react'
import { Switch } from 'antd'
import { useRouter } from 'next/router'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'

import { switchFilterSection } from 'store/modules/ProvidersFilter'
import { RootState } from 'store/reducer'
import { applyFilters, trackFilterUsage } from 'helpers/filters'

import FormattedMessage from 'components/FormattedMessage'

const ToggleFilterSection: React.FC<ToggleFilterSectionProps> = (props) => {
  const {
    offSection,
    onSection,
    apply = true,
  } = props
  const router = useRouter()
  const dispatch = useDispatch()
  const {
    checked,
    disabled,
  } = useSelector((state: RootState) => ({
    checked: state.providersFilter.filters.section === onSection,
    disabled: state.providers.loading.isActive,
  }), shallowEqual)

  const changeHandler = (checked: boolean) => {
    const filterSection = checked ? onSection : offSection

    trackFilterUsage({ toggle: filterSection })
    dispatch(switchFilterSection(filterSection, () => {
      if (apply) {
        applyFilters(router)
      }
    }))
  }

  return (
    <div className="provider-filter__switch-filter">
      <Switch
        checked={checked}
        disabled={disabled}
        onChange={changeHandler}
      />

      <label className="provider-filter__switch-filter__label" onClick={() => changeHandler(!checked)}>
        <FormattedMessage id={`newProviderFilters.toggle.${onSection}.title`} />
      </label>
    </div>
  )
}

interface ToggleFilterSectionProps {
  offSection: 'events' | 'meeting_locations' | 'conference_congress',
  onSection: 'events_ideas' | 'meeting' | 'conference_congress_ideas',
  apply: boolean,
}

export default ToggleFilterSection
