import Currency from 'currency.js'

const regexEuropean = /^(?:0|[1-9][0-9]*|[1-9][0-9]{0,2}(?:\.[0-9]{3})+)(?:,[0-9]{2})?$/
const regexAmerican = /^(?:0|[1-9][0-9]*|[1-9][0-9]{0,2}(?:,[0-9]{3})+)(?:\.[0-9]{2})?$/

export const validateCurrency = (value) => {
  const numericPart = value[0] === '-' ? value.slice(1) : value

  return (regexEuropean.test(numericPart) || regexAmerican.test(numericPart))
}

export const convertCurrencyToFloat = (value) => {
  if (regexEuropean.test(value)) {
    return parseFloat(value.replace(/\./g, '').replace(/,/g, '.'))
  }

  if (regexAmerican.test(value)) {
    return parseFloat(value.replace(/,/g, ''))
  }

  return null
}

export const currencyConfig = (intl) => {
  return {
    name: intl.formatMessage({ id: 'common.currencyName' }),
    symbol: intl.formatMessage({ id: 'common.currencySign' }),
    decimal: intl.formatMessage({ id: 'common.currencyDecimal' }),
    separator: intl.formatMessage({ id: 'common.currencySeparator' }),
  }
}

export const currency = (value, intl) => Currency(value, currencyConfig(intl))

export default currency
