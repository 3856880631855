import React, { useMemo } from 'react'

import { escapeRegex } from 'helpers/regex'

type HighlightCunk = {
  chunk: string,
  highlight: boolean,
}

const highlightFinder = (text: string, highlights: string[], index: number): HighlightCunk[] => (
  text
    .split(new RegExp(`(${escapeRegex(highlights[index])})`, 'gi'))
    .reduce((collection: HighlightCunk[], chunk) => {
      if (chunk.toLowerCase() === highlights[index].toLowerCase()) {
        return [
          ...collection,
          {
            chunk,
            highlight: true,
          },
        ]
      }

      const nextIndex = index + 1

      if (!highlights[nextIndex]) {
        return [
          ...collection,
          {
            chunk,
            highlight: false,
          },
        ]
      }

      return [
        ...collection,
        ...highlightFinder(chunk, highlights, nextIndex),
      ]
    }, [])
)

const HighlightedText: React.FC<HighlightedTextProps> = (props) => {
  const {
    text,
    highlights,
  } = props

  const sortedHighlights = useMemo(() => highlights.filter(Boolean).sort((a, b) => b.length - a.length), [highlights])

  if (sortedHighlights.length === 0) {
    return <>{text}</>
  }

  const highlightChunks = highlightFinder(text, sortedHighlights, 0)

  return (
    <>
      {highlightChunks.map(({ chunk, highlight }, index) => (
        <React.Fragment key={index}>
          {highlight ? <b>{chunk}</b> : chunk}
        </React.Fragment>
      ))}
    </>
  )
}

interface HighlightedTextProps {
  text: string
  highlights: string[]
}

export default HighlightedText
