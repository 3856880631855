import { UseFormReturn } from 'react-hook-form'
import { IntlShape } from 'react-intl'

import { currency, validateCurrency } from 'helpers/currency'
import { isDateInPast } from 'helpers/date'

// eslint-disable-next-line max-len
export const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const validPrice = (errorMessage: string) =>
  (value: string) => !(value && !validateCurrency(value)) || errorMessage

export const notZeroPrice = (intl: IntlShape, errorMessage: string) =>
  (value: string) => !(value && currency(value, intl).value <= 0) || errorMessage

export const notPastDate = (errorMessage: string) => (value: string) => {
  const valueArray = Array.isArray(value) ? value : [value]

  return (value && !valueArray.some(value => isDateInPast(value))) || errorMessage
}

export const trimRequired = (errorMessage: string, required = true) =>
  (value: string) => {
    if (!required) return true

    return !!value?.trim() || errorMessage
  }

export const greaterThan = (
  greater: number,
  errorMessage: string,
) => (value: string) => {
  if (!greaterThan || !value) { return true }

  return parseInt(value) >= greater || errorMessage
}

export const greaterThanField = (
  form: UseFormReturn,
  greater: string,
  errorMessage: string,
) => (value: string) => {
  const greaterThanValue = form.getValues(greater)

  return greaterThan(greaterThanValue, errorMessage)(value)
}
