import React from 'react'
import { useFormState } from 'react-hook-form'
import classNames from 'classnames'

import { getFieldError } from 'components/Form/helpers'

import './index.less'

const FormItem: React.FC<FormItemProps> = (props) => {
  const {
    className,
    label,
    name,
    showError = true,
    showErrorsOf = name,
    children,
    ...restProps
  } = props

  const formState = useFormState()
  const error = showErrorsOf && getFieldError(formState, showErrorsOf)

  return (
    <div
      className={classNames(className, 'form__item', {
        'form__item--invalid': !!error,
      })}
      {...restProps}
    >
      <div className="form__item__head">
        {label && (
          <label className="form__item__head__label">
            {label}
          </label>
        )}

        {error && showError && (
          <div className="form__item__head__error">{error.message}</div>
        )}
      </div>

      <div className="form__item__input">
        {children}
      </div>
    </div>
  )
}

interface FormItemProps {
  className?: string,
  label?: React.ReactNode,
  name?: string,
  showErrorsOf?: string,
  showError?: boolean,
  children: React.ReactNode,
}

export default FormItem
